import React from "react";
import "./App.css";
import {
  Provider,
  defaultTheme,
  Button,
  Grid,
  Footer,
  View,
  Content,
} from "@adobe/react-spectrum";
import { flow, makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";

class Store {
  images = [];
  collapedGroup = true;

  constructor() {
    makeAutoObservable(this);
    this.load();
  }

  setCollapedGroup(v: boolean) {
    this.collapedGroup = v;
  }

  load = flow(function* load(this: Store) {
    const r = yield fetch("/pics1/catalogue.json");
    this.images = yield r.json();
  });

  load1 = flow(function* load(this: Store) {
    const r = yield fetch("/info.json");
    this.images = yield r.json();
  });
}

const store = new Store();

const MultipleImagesRibbon = observer((props) => {
  return (
    <div className="ribbon ribbon-top ribbon-start">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M15 3v4a1 1 0 0 0 1 1h4" />
        <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
        <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
      </svg>
    </div>
  );
});

const mksrc = (img) => {
  return "/pics1/" + img.thumb300.replace("\\", "/");
};

export const Image = observer((props) => {
  // @ts-ignore
  const { image } = props;
  if (image.type === "group") {
    if (store.collapedGroup) {
      const imageI = image.images[0];
      return (
        <div className="image1 col-3">
          <div className="card card-sm">
            <MultipleImagesRibbon />
            <div className="d-block">
              <img src={mksrc(imageI)} className="card-img-top" />
            </div>
            <div className="card-body">{imageI.filename}</div>
          </div>
        </div>
      );
    } else {
      return image.images.map((i: any) => (
        // @ts-ignore
        <Image key={i.name} image={i} />
      ));
    }
  } else {
    return (
      <div className="image1 col-3">
        <div className="card card-sm">
          <div className="d-block">
            <img src={mksrc(image)} className="card-img-top" />
          </div>
          <div className="card-body">{image.filename}</div>
        </div>
      </div>
    );
  }
});

export const Images = observer(() => {
  return (
    <div className="images1 row row-cards">
      {store.images.map((i: any) => (
        // @ts-ignore
        <Image key={i.name} image={i} />
      ))}
    </div>
  );
});

export const Container = observer(() => {
  return (
    <div className="main1">
      <div className="content1">
        <Images />
      </div>
      <div className="footer1">
        <div className="navbar navbar-light">
          <div className="container">
            <label className="form-check form-switch">
              <input
                onChange={(evt) => {
                  store.setCollapedGroup(evt.target.checked);
                }}
                className="form-check-input"
                type="checkbox"
                checked={store.collapedGroup}
              />
              <span className="form-check-label">Collapase Similar</span>
            </label>
          </div>
        </div>
      </div>
      <div className="header1">header</div>
    </div>
  );
});

function App() {
  return (
    <Provider theme={defaultTheme}>
      <Container></Container>
    </Provider>
  );
}

export default App;
